@import '../../styles/variables';

.container {
	width: 100%;
	max-width: 100vw;
	background-image: url( '../../../public/loginBackground.png' );
	background-position: left bottom;
	background-size: cover;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	@media ( min-width: $desktop ) {
		background-position: left center;
		background-size: cover;
	}
}

.dialogue {
	margin: auto;
	@media ( min-width: $desktop ) {
		width: 30rem;
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 3rem;

		.submitButton {
			margin: 2rem auto;
		}
	}
}

.error {
	color: $red;
}

.title {
	text-align: center;
	color: $darkBlue;
	font-size: 1.6rem;
	margin: 0 auto;
}

.headerIcon {
	background-image: url( '../../../public/logo2.jpg' );
	width: 9rem;
	height: 9rem;
	background-position: center center;
	background-size: cover;
	margin: 0 auto;
}

.icon {
	height: 1.25rem;
	width: 1.25rem;
	fill: $darkBlue;
}

.forgotPassword {
	text-align: right;
	color: $pink;
	font-size: 1.125rem;
}

.noAccount {
	text-align: center;
	font-size: 1.125rem;
}
