// Main palette
$green: rgb( 4, 149, 95 );
$darkBlue: rgb( 32, 58, 96 );
$pink: rgb( 243, 142, 125 );
$gray: rgba( 0, 0, 0, 0.3 );
$lightGray: rgba( 0, 0, 0, 0.03 );
$mediumGray: rgba( 238, 237, 240, 1 );
$translucentLightGray: #f5f5f5a1;
$darkGray: rgb( 196, 196, 196 );
$white: #ffffff;
$black: #000000;
$red: #d32f2f;
$readableGray: #949494; // this is better than black with opacity

// TODO REMOVE THE BELOW
$lightGreen: #d5ece1;
$lightBlue: #b6d3e2;

// Message and Error handling
$error: #cc0000;
$warning: #fbbc05;
$success: #34a853;

// Shades
$lightestShade: #f9f9f9;
$lightShade: #eaf1f4;
$middleShade: #e7e9e8;
$darkerShade: #848a87;
$darkShade: #494b4a;

/* colord for MUI: primary, hover, and press colors, respectively */
$muiGreen: #04955f; /* hex of green above */
$muiDarkGreen: #026642;
$muiLightGreen: #36a77f;
$muiLightestGreen: #f3fffa; /* for the hover background on an outline button */
$muiBlue: #203a60;
$muiDarkBlue: #162843;
$muiLightBlue: #4c617f;
$muiLightestBlue: #f3faff; /* for the hover background on an outline button */

// Border
$lightBorderColor: #e1e1e1;
$lightBorder: 1px solid rgba( 207, 206, 207, 0.37 );
$errorBorder: 1px solid #ff0000;

// Breakpoints
$tablet: 768px;
$desktop: 960px;
$xlargeScreen: 1140px;
$largeScreen: 850px;
$mediumScreen: 640px;
$minWidth: 320px;

// drop-shadows
$boxshadowBasic: 0 0 8px rgba( 221, 221, 221, 0.15 ),
	30px 30px 60px rgba( 221, 221, 221, 0.25 );
$boxShadowSmall: 0 0 4px rgba( 221, 221, 221, 0.5 );

// just copied from MUI
$boxShadowMuiPaper: 0 5px 5px -3px rgba( 0, 0, 0, 0.2 ),
	0 8px 10px 1px rgba( 0, 0, 0, 0.14 ), 0 3px 14px 2px rgba( 0, 0, 0, 0.12 );
$boxShadowTransitionMuiPaper: box-shadow 300ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms;

// Header
$headerHeight: 4rem;

// Animation
$pulseAnimationDuration: 3000ms;
